import React from "react"
import Img from "gatsby-image"
import Fade from "react-reveal/Fade"

import "./AboutImage.scss"

const AboutImage = ({ firstParagraph, secondParagraph, image }) => {
  return (
    <section className="about-image">
      <Fade bottom distance="40px">
        <div className="about-image__image">
          <Img
            fluid={image.localFile.childImageSharp.fluid}
            alt={image.altText}
          />
        </div>
        <div className="about-image__content">
          <h2 className="about-image__title">{firstParagraph}</h2>
          <div className="about-image__description">
            {secondParagraph.split("\n").map((el, i) => (
              <p key={i}>{el}</p>
            ))}
          </div>
        </div>
      </Fade>
    </section>
  )
}

export default AboutImage

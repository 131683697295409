import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Link from "../../utils/link"

import SectorsCompact from "../SectorsCompact"
import FooterCompact from "./FooterCompact"

import "./FooterStandard.scss"

const FooterStandard = () => {
  const data = useStaticQuery(graphql`
    query {
      wp {
        siteSettings {
          options {
            footerText
          }
        }
      }
    }
  `)

  const openBooking = (event) => {
    event.preventDefault()
    window.ubookr.addBooking()
  }

  return (
    <>
      <footer className="footer-standard">
        <div className="footer-standard__container">
          <div className="footer-standard__content">
            <div className="footer-standard__content__text">
              {data.wp.siteSettings.options.footerText}
            </div>
            <div className="footer-standard__content__links">
              <Link to="#" onClick={openBooking}>
                Property Booking
              </Link>
              <Link to="/contact">Contact</Link>
            </div>
          </div>
          <div className="footer-standard__sectors">
            <SectorsCompact isSmall />
          </div>
        </div>
      </footer>
      <FooterCompact isDark />
    </>
  )
}

export default FooterStandard

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Fade from "react-reveal/Fade"

import ProfileModal from "../ProfileModal"

import "./AboutHero.scss"

const AboutHero = () => {
  const [showModal, setIsShowModal] = React.useState(false)
  const [modalData, setIsModalData] = React.useState(false)

  const openModal = (data) => () => {
    document.querySelector("body").classList.add("model-open")
    setIsModalData(data)
    setIsShowModal(true)
  }

  const closeModal = () => {
    document.querySelector("body").classList.remove("model-open")
    setIsShowModal(false)
  }

  const data = useStaticQuery(graphql`
    query {
      wpPage(databaseId: { eq: 270 }) {
        acf {
          whoWeAre
          title
        }
      }

      allWpTeammember(sort: { fields: menuOrder }) {
        edges {
          node {
            acf {
              bodyCopy
              introduction
              jobTitle
              name
              email
              phone
              profilePicture {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 222) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      {showModal && (
        <ProfileModal
          showModal={showModal}
          toggleModal={closeModal}
          data={modalData}
        />
      )}
      <div className="content">
        <section className="about">
          <div className="about__content">
            <Fade bottom distance="40px">
              <div className="about__content__title">
                {data.wpPage.acf.title}
              </div>
            </Fade>
          </div>
          <div className="about__images">
            {data.allWpTeammember.edges.map((el, i) => (
              <Fade bottom distance="40px" delay={i * 100} key={i}>
                <div
                  className="about__images__column"
                  onClick={openModal(el.node.acf)}
                  key={i}
                >
                  <Img
                    fluid={
                      el.node.acf.profilePicture.localFile.childImageSharp.fluid
                    }
                    alt={el.node.acf.name}
                  />
                  <div className="about__images__column__name">
                    {el.node.acf.name}
                  </div>
                  <div className="about__images__column__position">
                    {el.node.acf.jobTitle}
                  </div>
                </div>
              </Fade>
            ))}
          </div>
        </section>
        <div className="about__content__subheading">
          <div className="about__content__text">
            <Fade bottom distance="40px">
              <div className="about__content__text__subtitle">Who we are</div>
            </Fade>
            {data.wpPage.acf.whoWeAre.split("\n").map((el, i) => (
              <Fade bottom distance="40px" key={i}>
                <p className="about__content__text__details" key={i}>
                  {el}
                </p>
              </Fade>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutHero

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Link from "../../utils/link"

import LogoLight from "../../assets/images/logo.svg"
import LogoDark from "../../assets/images/logo-dark.svg"
import "./FooterCompact.scss"

const FooterCompact = ({ isDark }) => {
  const footerClass = isDark ? "footer-compact is-dark" : "footer-compact"
  const titleClass = isDark
    ? "footer-compact__content__details__detail__title is-dark"
    : "footer-compact__content__details__detail__title"
  const textClass = isDark
    ? "footer-compact__content__details__detail__text is-dark"
    : "footer-compact__content__details__detail__text"
  const copyClass = isDark
    ? "footer-compact__content__social-container__copy is-dark"
    : "footer-compact__content__social-container__copy"
  const socialClass = isDark
    ? "footer-compact__content__social-container__social is-dark"
    : "footer-compact__content__social-container__social"
  const copyrightClass = isDark
    ? "footer-compact__copyright is-dark"
    : "footer-compact__copyright"

  const data = useStaticQuery(graphql`
    query {
      wp {
        siteSettings {
          options {
            address
            email
            facebook
            generalEnquiriesPhone
            instagram
            linkedin
            salesPhone
          }
        }
      }
    }
  `)

  const { options } = data.wp.siteSettings

  return (
    <div className={isDark ? "has-background-primary" : ""}>
      <div className="content">
        <footer className={footerClass}>
          <div className="footer-compact__content">
            <div className="footer-compact__content__logo">
              <img
                src={isDark ? LogoLight : LogoDark}
                alt="Flip Property Video"
              />
            </div>
            <div className="footer-compact__content__details">
              <div className="footer-compact__content__details__detail">
                <div className={titleClass}>General Enquires</div>
                <div className={textClass}>
                  <Link to={`tel:${options.generalEnquiriesPhone}`}>
                    {options.generalEnquiriesPhone}
                  </Link>
                </div>
              </div>
              <div className="footer-compact__content__details__detail">
                <div className={titleClass}>Postal Address</div>
                <div
                  className={textClass}
                  dangerouslySetInnerHTML={{ __html: options.address }}
                />
              </div>
              <div className="footer-compact__content__details__detail">
                <div className={titleClass}>Email</div>
                <div className={textClass}>
                  <Link to={`mailto:${options.email}`}>{options.email}</Link>
                </div>
              </div>
            </div>
            <div className="footer-compact__content__social-container">
              <div className={copyClass}>
                <div>© {new Date().getFullYear()} Flip Property Video Pty Ltd</div>
                <div>Design LBD Studios + Atollon.</div>
              </div>
              <div className={socialClass}>
                <Link to={options.instagram}>
                  <i className="fab fa-instagram" />
                </Link>
                <Link to={options.facebook}>
                  <i className="fab fa-facebook-f" />
                </Link>
                <Link to={options.linkedin}>
                  <i className="fab fa-linkedin-in" />
                </Link>
              </div>
            </div>
          </div>
          <div className={copyrightClass}>
            <div>© {new Date().getFullYear()} Flip Property Video Pty Ltd</div>
            <div>
              Design{" "}
              <Link to="https://www.lbdstudios.com.au/">LBD STUDIOS</Link> +{" "}
              <Link to="https://atollon.com.au/">Atollon</Link>.
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}

FooterCompact.propTypes = {
  isDark: PropTypes.bool,
}

FooterCompact.defaultProps = {
  isDark: false,
}

export default FooterCompact

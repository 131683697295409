import React from 'react'
import { graphql } from 'gatsby'
import he from 'he'

import SEO from '../components/seo'
import Header from '../components/header'
import Footer from '../components/footer'
import BottomBar from '../components/BottomBar'

import AboutHero from '../components/about/AboutHero'
import AboutImage from '../components/about/AboutImage'
import AboutContent from '../components/about/AboutContent'

const AboutPage = ({ data }) => {
  const page = data.wpPage

  return (
    <>
      <SEO path="/about" title={he.decode(page.seo.title)} description={he.decode(page.seo.metaDesc)} />
      <Header isDark />
      <AboutHero />
      <AboutImage {...page.acf.imageContent} />
      <AboutContent {...page.acf.lowerText} />
      <Footer />
      <BottomBar />
    </>
  )
}

export const pageQuery = graphql`
  query {
    wpPage(databaseId:{eq:270}) {
      seo {
        title
        metaDesc
      }
      acf {
        lowerText {
          leftText
          rightText
        }
        imageContent {
          image {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          firstParagraph
          secondParagraph
        }
      }
    }
  }
`

export default AboutPage

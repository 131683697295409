import React from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'

import Link from '../utils/link'

import './ProfileModal.scss'

const ProfileModal = ({ showModal, toggleModal, data }) => {
  return (
    <section className="profile-modal">
      <div className="profile-modal__background" onClick={toggleModal} />
      <aside className="profile-modal__sidebar">
        <div className="profile-modal__sidebar__container">
          <button
            className="profile-modal__sidebar__container__close"
            onClick={toggleModal}
          >
            Close
          </button>

          <div className="profile-modal__sidebar__container__content">
            <div className="profile-modal__sidebar__container__content__image">
              <Img
                fluid={data.profilePicture.localFile.childImageSharp.fluid}
                alt={ data.name }
              />
            </div>
            <div className="profile-modal__sidebar__container__content__details">
              <div className="profile-modal__sidebar__container__content__details__name">
                { data.name }
              </div>
              <div className="profile-modal__sidebar__container__content__details__position">
                { data.jobTitle }
              </div>
              <div className="profile-modal__sidebar__container__content__details__title">
                { data.introduction }
              </div>
              <div className="profile-modal__sidebar__container__content__details__bio">
                { data.bodyCopy.split('\n').map((el, i) => (<p key={i}>{ el }</p>)) }
              </div>
              <div className="profile-modal__sidebar__container__content__details__links">
                { data.email && <a href={`mailto:${data.email}`}>Email</a> }
                { data.phone && <a href={`tel:${data.phone}`}>Contact</a> }
              </div>
            </div>
          </div>
        </div>
      </aside>
    </section>
  )
}

ProfileModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired
}

export default ProfileModal

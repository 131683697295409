import React from "react"
import Fade from "react-reveal/Fade"

import "./AboutContent.scss"

const AboutContent = ({ leftText, rightText }) => {
  return (
    <section className="about-content">
      <Fade bottom distance="40px">
        <h2 className="about-content__title">{leftText}</h2>
        <div className="about-content__description">
          {rightText.split("\n").map((el, i) => (
            <p key={i}>{el}</p>
          ))}
        </div>
      </Fade>
    </section>
  )
}

export default AboutContent

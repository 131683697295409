import React from 'react'
import PropTypes from 'prop-types'

import FooterCompact from './FooterCompact'
import FooterStandard from './FooterStandard'

const Footer = ({ isCompact }) => {
  return isCompact ? <FooterCompact /> : <FooterStandard />
}

Footer.propTypes = {
  isCompact: PropTypes.bool
}

Footer.defaultProps = {
  isCompact: false
}

export default Footer
